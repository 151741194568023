<template>
  <v-app>
    <main>
      <v-container
        id="user-profile"
        fluid
        tag="section"
      >
        <br>
        <v-row
          align="center"
          justify="center"
        >
          <img
            :src="logo"
          >
        </v-row>
        <br>
        <v-card
          class="my-base-vcard-style"
        >
          <br>
          <br>
          <v-row
            class="my-style"
            align="center"
            justify="center"
          >
            <v-col
              class="elevation-10"
              cols="12"
              sm="6"
            >
              <h2 class="title-style">
                <v-icon class="icon-style">
                  mdi-email-check
                </v-icon> Confirm Email
              </h2>
              <v-card
                class="my-vcard-style"
              >
                <v-container class="py-0">
                  <v-card-text>
                    <v-form
                      ref="form"
                      v-model="valid"
                    >
                      <br>
                      <br>
                      <v-text-field
                        v-model="email"
                        hint="Enter your registered mail id here."
                        label="Email*"
                        :rules="emailRules"
                        outlined
                        required
                      />
                    </v-form>
                  </v-card-text>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="mt-2"
                  color="red darken-3"
                  @click="back()"
                >
                  Back
                </v-btn>
                <v-btn
                  class="mt-2"
                  color="#FF3700"
                  @click="resetPassword()"
                >
                  Reset Password
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <br>
          <br>
        </v-card>
        <dashboard-core-footer />
      </v-container>
    </main>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'PasswordResetInitialPage',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        email: '',
        valid: false,
        loading: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        ],
        logo: `${cdnUrl}/website/NewDBSLogo.png`,
      };
    },
    methods: {
      resetPassword () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the registered email address before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('passwordreset/resetPassword', {
          email: this.email,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'If your email is valid, you will receive an email with instructions on how to reset your password.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Login' });
        }).finally(
          this.loading = false,
        );
      },
      back () {
        this.$router.push({ name: 'Login' });
      },
    },
  };
</script>
<style scoped>
.my-style {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}
.title-style {
  color: #37474F;
  text-align: center;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 15px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
  border-radius: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
</style>
